import React, { useState } from 'react'
import './Navbar.css'

import logo from '../Assets/logo.png'
import cart_icon from '../Assets/cart_icon.png'
import { Link } from 'react-router-dom'

const Navbar = () => {

    const [menu,setMenu] = useState("shop");

  return (
    <div className='navbar'>
      <div className="nav-logo">
      <img className='' height={50} src="/V-Attire-logo.png" alt="V-ATTIRE"/>
      </div>
      <ul className="nav-menu">
        <li onClick={()=>{setMenu("shop")}}><Link style={{ textDecoration: 'none' }} to='/'>Shop</Link>{menu==="shop"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("mens")}}><Link style={{ textDecoration: 'none' }} to='/mens'>Mens</Link>{menu==="mens"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("aboutus")}}><Link style={{ textDecoration: 'none' }} to='/aboutus'>Aboutus</Link>{menu==="aboutus"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("contactus")}}><Link style={{ textDecoration: 'none' }} to='/contactus'>Contactus</Link>{menu==="contactus"?<hr/>:<></>}</li>
      </ul>
      <div className="nav-login-cart">
        <Link to='/login'><button>Login</button></Link>
        <Link to='/cart'><img src={cart_icon} alt="" /></Link>
        <div className="nav-cart-count">0</div>
      </div>
    </div>
  )
}

export default Navbar
  