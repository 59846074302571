import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import './Footer.css'; // For custom styles
import { useStickyFooter } from './hooks/useStickyFooter';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* About Us Section */}
        <div className="footer-about">
          <h3>About Us</h3>
          <p>
            We are a passionate team committed to providing the best service and
            products to our customers. Learn more about our journey and values.
          </p>
        </div>

        {/* Contact Us Section */}
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <ul>
            <li>Email: info@vattire.com</li>
            <li>Mobile: +123 456 7890</li>
            <li>Address: 123 Main St, Nagpur, Nagpur</li>
          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="footer-social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} V-Attire. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
