import React from 'react'
import './Aboutus.css';

const AboutUs = () => {

  return ( 
    <div className="value-rapper">
      <section className="about-hero">
        <div className="hero-text">
          <h1>Welcome to V-Attire</h1>
          <p>Your ultimate destination for stylish, comfortable, and affordable clothing!</p>
        </div>
      </section>

      <section className="about-content">
        <div className="about-story">
          <h2>Our Story</h2>
          <p>
            Founded in 2024, V-Attire was born out of a passion for fashion and the desire to
            provide high-quality clothing to customers worldwide. We believe fashion is not just
            about looking good but feeling good in what you wear. Our collections are carefully
            crafted to bring you the latest trends while ensuring comfort and affordability.
          </p>
        </div>

        <div className="about-mission">
          <h2>Our Mission</h2>
          <p>
            At V-Attire, our mission is to empower individuals through fashion by offering versatile,
            stylish, and sustainable clothing that fits every lifestyle. We strive to make fashion
            accessible and fun for everyone, without compromising on quality or comfort.
          </p>
        </div>

        <div className="about-values">
  <h2>Our Values</h2>
  <ul>
    <li className="value-box quality">
      <strong>Quality:</strong> We prioritize high-quality materials and craftsmanship.
    </li>
    <li className="value-box affordability">
      <strong>Affordability:</strong> Style should be accessible to everyone.
    </li>
    <li className="value-box sustainability">
      <strong>Sustainability:</strong> We are committed to eco-friendly practices in production.
    </li>
    <li className="value-box inclusivity">
      <strong>Inclusivity:</strong> Fashion for all sizes, genders, and walks of life.
    </li>
  </ul>
</div>



        <div className="about-team">
          <h2>Meet Our Team</h2>
          <p>
            Our team is made up of passionate individuals who love fashion as much as you do.
            From designers to customer service, we work together to ensure you have the best
            shopping experience possible.
          </p>
        </div>
      </section>

      <section className="about-cta">
        <h2>Join the V-Attire Family</h2>
        <p>Discover the latest trends and timeless styles. Shop now and experience the V-Attire difference!</p>
        <a href="/shop" className="shop-btn">Shop Now</a>
      </section>
    </div>
  );
};

export default AboutUs;


